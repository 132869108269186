<template>
	<a-spin :spinning="spinning">
		<div class="modal-header">
			<div class="modal-title">
				<div>批量修改状态</div>
			</div>
		</div>
		<div>
			<a-row class="d-flex align-items-center">
				<a-col>
					状态：
				</a-col>
				<a-col>
					<a-select placeholder="状态" style="width:200px" v-model="state">
						<a-select-option v-for="item in dailySummaryStateList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
		</div>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button @click="close">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save">
				<a-icon type="save" />
				保存
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import { ModalComponentBase } from '@/shared/component-base';
import {
	EmpattdailyServiceProxy,
	EmpattmonthlyServiceProxy,
	EmpattmonthlyUpdateStateDto,
	UpdateStateDto,
} from '../../../../shared/service-proxies';
import { Dic } from '../../../../shared/utils';

export default {
	name: 'batch-edit',
	mixins: [ModalComponentBase],

	data() {
		return {
			spinning: false,
			dailySummaryStateList: [],
			state: undefined,
			groupListApi: undefined,
			ids: [],
			isMonth: false,
		};
	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		//考勤组
		this.groupListApi = new EmpattdailyServiceProxy(this.$apiUrl, this.$api);
		this.monthGroupListApi = new EmpattmonthlyServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.dailySummaryStateList = await Dic.getInstance().getDicAsync('QYB_DailySummaryState');
	},
	watch: {},
	methods: {
		save() {
			if (this.isMonth) {
				let entity = new EmpattmonthlyUpdateStateDto();
				entity.ids = this.ids;
				entity.stateCode = this.state;
				this.monthGroupListApi.updateState(entity).then((res) => {
					this.success(true);
					this.close();
				});
			} else {
				let entity = new UpdateStateDto();
				entity.ids = this.ids;
				entity.stateCode = this.state;
				this.groupListApi.updateState(entity).then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
			}
		},
	},
};
</script>

<style scoped></style>

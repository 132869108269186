<template>
	<div>
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<span>{{ l('选择账号') }}</span>
			</div>
		</div>
		<!-- usePagination开启分页 -->
		<!-- loading绑定引入页面的蒙层加载变量 -->
		<q-container usePagination :loading="loading" useModal>
			<!-- 使用具名插槽 #toolbar -->
			<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
			<template #toolbar>
				<div class="my-md select-input">
					<p v-if="selectedRows.length <= 0" class="NotR">
						已选人员
					</p>
					<a-tag closable @close="tagcol(item.id)" v-for="item in selectedRows" :key="item.id" v-else>
						{{ item.realName }}
					</a-tag>
				</div>
				<div class="my-md">
					<a-input-search
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
					/>
				</div>
			</template>
			<!-- 使用具名插槽slot="table" -->
			<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
			<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

			<!-- **************行选择*************** -->
			<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
			<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
			<template slot="table" slot-scope="scopeParam">
				<a-table
					ref="table"
					size="small"
					:pagination="false"
					:columns="columns"
					:rowKey="(tableDatas) => tableDatas.id"
					:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
					:dataSource="tableData"
					:customRow="customRow"
					:rowClassName="rowClassName"
				>
					<span slot="UserImg" slot-scope="text, record">
						<a-avatar
							shape="square"
							size="large"
							icon="user"
							:src="imgPath + record.profilePictureId + record.userPictureType"
							v-if="record.profilePictureId && record.userPictureType"
						/>
						<a-avatar shape="square" size="large" style="backgroundColor:rgb(0, 162, 174)" v-else>
							{{ record.realName ? record.realName : record.userName }}
						</a-avatar>
					</span>
					<span slot="roles" slot-scope="text, record">
						<a-tag color="#2db7f5" v-for="(item, index) in record.roles" :key="index">
							{{ item.roleName }}
						</a-tag>
					</span>
					<span slot-scope="text, record" slot="isLockoutEnabled">
						<a-icon type="lock" v-if="record.isLockoutEnabled" />
					</span>
				</a-table>
			</template>
			<!-- 使用具名插槽 #page -->
			<template #page>
				<!-- 分页组件只需复制以下代码 -->
				<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
				<a-pagination
					v-model="pageNumber"
					:total="totalItems"
					showSizeChanger
					size="small"
					:defaultPageSize="request.maxResultCount"
					:pageSizeOptions="pageSizeOptions"
					:showTotal="() => showTotal"
					@change="onPageChange"
					@showSizeChange="showPageSizeChange"
				/>
			</template>
		</q-container>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</div>
</template>

<script>
import ModalComponentBase from '../../shared/component-base/modal-component-base';
import { AppConsts } from '../../abpPro/AppConsts';
import * as _ from 'lodash';
import { UserServiceProxy } from '../../shared/service-proxies';

export default {
	name: 'abp-user-select',
	mixins: [ModalComponentBase],
	data() {
		return {
			WorkTypeList: [],

			columns: [
				{
					title: this.l('头像'),
					dataIndex: 'UserImg',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'UserImg' },
				},
				{
					title: this.l('账号'),
					dataIndex: 'userName',
					sorter: false,
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'userName' },
				},
				{
					title: this.l('显示名称'),
					dataIndex: 'realName',
					sorter: false,
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('角色'),
					dataIndex: 'roles',
					sorter: false,
					align: 'center',

					scopedSlots: { customRender: 'roles' },
				},
				{
					title: this.l('备注'),
					dataIndex: 'remarks',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'remarks' },
				},
				{
					title: this.l('锁定'),
					dataIndex: 'isLockoutEnabled',
					align: 'center',
					scopedSlots: { customRender: 'isLockoutEnabled' },
				},
			],
			tableData: [],
			imgPath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/${abp.session.tenantId}/`,
			_selectedRowKeys: [],
			_selectedRows: [],
		};
	},
	computed: {
		mergeRows() {
			//根据ID得并集
			return [..._.unionBy(this._selectedRows, this.selectedRows, 'id')];
		},
	},
	created() {
		this.fullData();
		this._userServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;

			this._userServiceProxy
				.getLoginUserPage(
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
					//轮循key
					for (let key of this._selectedRowKeys) {
						//从本页数据中查询key
						const index = this.tableData.findIndex((d) => d.id == key);
						//如果存在
						if (index != -1) {
							//从已选中的数据中找到与本页一样ID的数据
							const keyIndex = this._selectedRows.findIndex((r) => r.id == this.tableData[index].id);
							//删除已选中的数据
							this._selectedRows.splice(keyIndex, 1);
							//重新选中
							this.rowSelect(this.tableData[index], index);
						}
					}
				});
		},
		//点击标签删除
		tagcol(record, id) {
			//从本页数据中找删除的这条数据
			const index = this.tableData.findIndex((t) => t.id == id);
			//删除本页数据
			if (index != -1) {
				//取消行选中 == 删除
				this.rowSelect(record, index);
			} //删除其他页数据
			else {
				//从已选中的keys中找到
				const keyIndex = this._selectedRowKeys.findIndex((r) => r == record.id);
				if (keyIndex != -1) {
					//删除key
					this._selectedRowKeys.splice(keyIndex, 1);
				}
				//从已选中的数据中找到
				const rowIndex = this._selectedRows.findIndex((r) => r.id == record.id);
				if (rowIndex != -1) {
					//删除数据
					this._selectedRows.splice(rowIndex, 1);
				}
			}
		},
		save() {
			console.log('this.mergeRows', this.mergeRows);
			this.success(this.success(this.mergeRows));
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
.ant-form-item {
	margin-bottom: 0px;
}
.select-input {
	border: 1px solid #d9d9d9;
	min-height: 45px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
}

.NotR {
	height: 20px;
	color: #bfbfbf;
}

.select-input::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}

.select-input::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}

.select-input::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}
</style>
